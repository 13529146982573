import React from 'react';
import { 
	Grid,
	Container,
	makeStyles,
	Typography,
	Paper
} from '@material-ui/core';
import Experience from '../components/Experience.js';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: '1em',
		paddingBottom: '1em'
	},
	paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
	linebreak : {
		whiteSpace: 'pre-line'
	}
}));

export default function Education() {
	const classes = useStyles();

  return (
		<Container className={classes.container} maxWidth="lg" id="education">
			
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container direction="column" spacing={2}>
					
						<Grid item>
							<Typography variant="h4" align="center">
								Education
							</Typography>
						</Grid>
						
						<Grid item>
							<Experience 
								title="PhD Biochemical Engineering, University College London - Pass (Publications Pending)"
								context="My PhD project focused on improving the bioprocessing platform of Human Induced Pluripotent Stem Cells. I looked at the characterisation of Human Induced Pluripotent Stem Cells differentiation outcome grown on different scaffold substrate stiffness and oxygen tension. I have over three years cell culturing experience of various human and mammalian cell types including human induced pluripotent stem cells, human embryonic stem cells, T cells, mesenchymal stem cells and mouse embryonic fibroblasts. Culturing experience includes day-to-day maintenance, maintaining cell line for whole lab, cryopreservation, spontaneous differentiation and directed differentiation in both 2D and 3D cultures. Additionally, my laboratory skills consist of multiple assays development, using the atomic force microscope, confocal microscopy, RT-qPCR, flow cytometry, immunocytochemistry and data analytic software such as Flowjo, Design Expert and Prism GraphPad."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="MSc Biochemical Engineering, University College London - Merit"
								context="Extensive courses which included upstream and downstream bioprocess development, Design of Experiment, Quality by Design, a bench to commercialisation project of a Multiple Myeloma CAR-T product and MSc design project on egg-based influenza manufacturing plant with the capability to upsurge production in a pandemic."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="BSc Nutrition and Food Science, University of Reading - 2:1"
								context="My dissertation project was to design and validate PCR primers for the Coriobacteriia family with interests in the Slackia species. The primers were used with DNA from human faecal samples from a previous longitudinal study investigating the ‘Atopobium cluster’ of healthy humans and by comparison to the previous results, enable investigation of the Slackia component of the Coriobacteriia population in healthy humans. Additionally, project developing a novel food product from “kitchen” to shelf including product labelling, marketing and pitching."
							/>
						</Grid>
						
					</Grid>
				</Paper>
			</Grid>
			
		</Container>
  );
}