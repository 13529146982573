import React from 'react';
import FrontPage from './sections/FrontPage.js';
import NavBar from './components/NavBar.js';
import About from './sections/About.js';
import Education from './sections/Education.js';
import Experience from './sections/Experience.js';
import Parallax from './components/Parallax.js';
import Footer from './sections/Footer.js';

import Top from './images/Coral.jpg';
import Middle from './images/London.jpg';
import AboveFooter from './images/Island.jpg';

function App() {
  return (
    <div>
      <NavBar/>
			<FrontPage/>
			<About/>
			<Parallax image={Top}/>
			<Education/>
			<Parallax image={Middle}/>
			<Experience/>
			<Parallax image={AboveFooter}/>
			<Footer/>
    </div>
  );
}

export default App;
