import React from 'react';
import { 
	Grid,
	Container,
	makeStyles,
	Typography,
	Paper
} from '@material-ui/core';
import Experience from '../components/Experience.js';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: '1em',
		paddingBottom: '1em'
	},
	paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
	linebreak : {
		whiteSpace: 'pre-line'
	}
}));

export default function Experiences() {
	const classes = useStyles();

  return (
		<Container className={classes.container} maxWidth="lg" id="experience">
			
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container direction="column" spacing={2}>
					
						<Grid item>
							<Typography variant="h4" align="center">
								Experience
							</Typography>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Part time Dog Walker - Wags of Westminster "
								context="Provide one-to-one dog companion walking services for four legged clients. I absolutely love dogs and this job is a benefit for me more than for them!"
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Founder of Houseplant foodies and co-founder of Asian Aroids"
								context="Amongst my many hobbies, growing houseplants is probably one of the prominent ones. I like to collect, grow and propagate rare houseplants. I started @houseplant_foodies Instagram page with over 6000 followers and also co-founded @Asian_Aroids, where I hobby sell cuttings of the rare houseplants I propagate."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Senior Postgraduate Teaching Assistant - UCL Engineering Faculty"
								context="During my PhD I worked part-time as a teaching assistant which included leading, assisting and organising fellow teaching assistants for seminars, teaching and marking assignments of undergraduate engineering students. Additionally, I was also part of the teaching team for the Stem Cell Training Course: Human Pluripotent Stem Cells in Culture and the award winning “How To Change The World” module in the Integrated Engineering Program (IEP)."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Co-founder of Dimensions Activewear"
								context="Scuba diving is another passionate hobby of mine. After discovering how most (if not all) Scuba diving gear are plain, dull colours which are not photogenic. Therefore, I co-founded an activewear brand providing scuba diving attire that I designed myself taking inspiration from the marine life in my favourite dive region, the Andaman Sea."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Thai Theparos Public Company Ltd., Thailand (Golden Mountain Sauce)"
								context="One of the leading sauce manufacturers in Thailand that distributes globally with products including soy sauce, oyster sauce, chilli sauce, ketchup and the original sriracha sauce."
							/>
						</Grid>
						
						<Grid item>
							<Experience 
								title="Personal Achievements"
								context=""
							/>
							<Typography variant="body1" className={classes.linebreak}>
								•	Over 40kg weight loss
							</Typography>
							<Typography variant="body1" className={classes.linebreak}>
								•	London Marathon 
							</Typography>
							<Typography variant="body1" className={classes.linebreak}>
								•	Athens Marathon  
							</Typography>
							<Typography variant="body1" className={classes.linebreak}>
								•	Various half marathons 
							</Typography>
							<Typography variant="body1" className={classes.linebreak}>
								•	Sprint triathlons.
							</Typography>
							<Typography variant="body1" className={classes.linebreak}>
								•	PADI Rescue Diver (with Enriched Air, Deep Diving, Wreck Diving and Dry Suit Specialities).
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			
		</Container>
  );
}