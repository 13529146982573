import React from 'react';
import { makeStyles, 
	createMuiTheme, 
	responsiveFontSizes,
	Typography
} from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Parallax, Background } from 'react-parallax';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  entry: {
		height: '100vh',
		backgroundImage: `url(${Background})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top',
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		display: 'grid',
		alignItems: 'center'
	},
	title: {
		textAlign: 'center',
		backgroundColor: 'rgba(255,255,255,.75)'
	},
	style: {
		height: '100vh',
		display: 'grid',
		alignItems: 'center'
	}
}));

export default function MyAppBar() {
	const classes = useStyles();
  return (
		<div>
			<Parallax
				bgImage={require('../images/Plants.jpg')}
				strength={400}
			>
				<div className={classes.style}>
					<div className={classes.title}>
						<ThemeProvider theme={theme}>
							<Typography variant="h3">Nuttinee (Fair) Yongsanguanchai, PhD, AMIChemE</Typography>
							<Typography variant="h4">Biochemical Engineering | London</Typography>
						</ThemeProvider>
					</div>
				</div>
			</Parallax>
		</div>
  );
}