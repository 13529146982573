import React from 'react';
import { 
	Grid,
	Container,
	makeStyles,
	Typography,
	Paper,
	Button
} from '@material-ui/core';
import Me from '../images/Fair.jpg';
import LinkedInLogo from '../images/linkedin-icon.svg';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: '1em',
		paddingBottom: '1em'
	},
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
	img: {
    width: '100%',
		borderRadius: '50%'
  },
	logoWrapper: {
		justifyContent: 'center'
	},
	logo: {
		height: '2em',
		width: '2em'
	}
}));

export default function About() {
	const classes = useStyles();

  return (
		<Container className={classes.container} maxWidth="lg" id="about">
			<Paper className={classes.paper}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Typography variant="h4" gutterBottom align="center">
							Personal
						</Typography>
						<Typography variant="body1" gutterBottom>
							Hi there, I’m Nuttinee, (but everyone calls me Fair)! Thank you very much for taking the time to visit my site and I hope it provides a little more information about me as a person. As my pictures suggest, I have a few hobbies! I am somewhat a plant lover with my own plant instagram (@houseplant_foodies) which I'd love you to check out :). Other than obsessing over my plants, I spend my free time reading sci-fi books, cooking, baking, scuba diving and the occasional half marathon!
						</Typography>
						<Typography variant="body1" gutterBottom>
							I'm a Bangkok girl in London so always adding a bit of spice to my food on the sly and you will often catch me trying to find my next scuba diving destination or passionately explaining the benefits of cellular agriculture ("lab grown meat")! 
						</Typography>
					</Grid>
					<Grid item xs={12} sm={4}>
						<img className={classes.img} alt="complex" src={Me} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography variant="h4" gutterBottom align="center">
							Professional
						</Typography>
						<Typography variant="body1" gutterBottom>
							I recently completed my PhD in Regenerative Medicine at the Biochemical Engineering department of University College London (UCL) in the UK. My research focused on improving the bioprocessing platform of Human Induced Pluripotent Stem Cells by culturing the cells in various oxygen level and substrate stiffness. I also have experience with other cell types such as hESCs, T cells, MSCs and mammalian cells. Additionaly, I also have a MSc in Biochemical Engineering from UCL and a BSc in Nutrition and Food Science from University of Reading.
						</Typography>
						<Grid container className={classes.logoWrapper}>
							<Button href="https://www.linkedin.com/in/nuttinee-yongsanguanchai/" target="_blank">
								<img className={classes.logo} alt="" src={LinkedInLogo} />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Container>
  );
}
