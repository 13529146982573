import React from 'react';
import { 
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
	linebreak : {
		whiteSpace: 'pre-line'
	}
}));

export default function Experience(props) {
	const classes = useStyles();
	
	return (
		<Grid>
			<Grid item>
				<Typography variant="h5">
					{props.title}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1" className={classes.linebreak}>
					{props.context}
				</Typography>
			</Grid>
		</Grid>
	);
}
