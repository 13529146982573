import React from 'react';
import { 
	makeStyles,
	Container,
	Grid,
	Typography,
	Button
} from '@material-ui/core';
import LinkedInLogo from '../images/linkedin-icon.svg';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: '2em',
		paddingBottom: '1em',
		textAlign: 'center'
	},
	logo: {
		height: '2em',
		width: '2em'
	}
}));

export default function MyAppBar() {
	const classes = useStyles();
  return (
		<Container className={classes.container} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button href="https://www.linkedin.com/in/nuttinee-yongsanguanchai/" target="_blank">
						<img className={classes.logo} alt="" src={LinkedInLogo} />
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1" gutterBottom>
						© Dr Nuttinee Yongsanguanchai | 2020
					</Typography>
					<Typography variant="body1" gutterBottom>
						This site collects no infomation on you at all!
					</Typography>
					<Typography variant="body1" gutterBottom>
						Built by Matthew White
					</Typography>
				</Grid>
			</Grid>
		</Container>
  );
}