import React from 'react';
import {
	makeStyles 
} from '@material-ui/core/styles';
import { Parallax } from 'react-parallax';

const useStyles = makeStyles(theme => ({
	style: {
		height: '50vh'
	}
}));

export default function ParallaxBreak(props) {
	const classes = useStyles();
  return (
    <div>
			<Parallax
				bgImage={props.image}
				strength={500}
			>
				<div className={classes.style} />
			</Parallax>
		</div>
  );
}