import React from 'react';
import { 
	makeStyles, 
	AppBar, 
	Toolbar, 
	IconButton, 
	Button, 
	Typography, 
	Container,
	Menu,
	MenuItem,
	Hidden
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
	title: {
    flexGrow: 1,
  }
}));

export default function NavBar() {
	const classes = useStyles();
	
	const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function nav(nav) {
		window.location.href = nav;
  }
	
  return (
    <div>
      <AppBar position="fixed">
				<Container maxWidth="lg">
					<Toolbar>
						
						<Typography variant="h6" className={classes.title}>
							nuttineeyongsanguanchai.com
						</Typography>
						<Hidden smUp>
							<IconButton 
								aria-controls="simple-menu" 
								aria-haspopup="true" 
								onClick={handleClick}
								color="inherit"
							>
								<MenuIcon/>
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={() => {handleClose(); nav("/#about")}}>About me</MenuItem>
								<MenuItem onClick={() => {handleClose(); nav("/#education")}}>Education</MenuItem>
								<MenuItem onClick={() => {handleClose(); nav("/#experiences")}}>Experience</MenuItem>
							</Menu>
						</Hidden>
						<Hidden xsDown>
							<Button color="inherit" href="/#about">About me</Button>
							<Button color="inherit" href="/#education">Education</Button>
							<Button color="inherit" href="/#experience">Experience</Button>
						</Hidden>
					</Toolbar>
				</Container>
      </AppBar>
    </div>
  );
}